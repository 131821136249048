import React from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import Layout from "../components/layout";
import SEO from "../components/seo";


const Resident = ({ data }) => {
  const { companyName, pitch, logo, location, website, contacts } = data.contentfulResident;
  const Residents = data.allContentfulResident.edges;

  return (
    <Layout>
      <SEO title={companyName} />
      <div className="">
        <Img
          className="object-cover w-full h-64"
          fluid={data.file.childImageSharp.fixed}
          alt="Header"
        />
      </div>
      <div className="container px-4 mx-auto mt-12 mb-40">
        <h1 className="title">{companyName}</h1>

        <div className="flex flex-wrap mt-10">


          <div className="sm:w-2/3">


     
            <div className="bg-white px-6 pt-8 pb-32 mt-12">
              <div className="pb-8">
                {pitch.pitch}
                {/* {documentToReactComponents(bio.json)} */} 
              </div>
              <div className="pb-12">
                <h2>Hub Base</h2>
                {location}
              </div>
              <div className="pb-12">
                <h2>{companyName} Website</h2>
                <a className="text-purple-800" href={website} target="_blank" rel="noreferrer">Visit {companyName}</a>
              </div>



              <div className="pb-12">
                <h2>Contact</h2>
                <div className="flex flex-wrap">
                  {
                    contacts.map((contacts) =>

                      <div className="pb-12 mr-16">
                        <div><strong>{contacts.name}</strong></div>
                        <div>{contacts.title}</div>
                        <div><a className="text-purple-800" href={"mailto:" + contacts.email}>{contacts.email}</a></div>
                      </div>


                    )
                  }
                </div>
              </div>

            </div>



          </div>
          <div className="hidden xl:block lg:w-1/3 px-16 pt-12">

            <Img
              className="object-cover w-full"
              fluid={logo.fluid}
              alt="Header"
            />


          </div>
        </div>
        <div className="hidden text-xs mt-12 w-full sm:w-2/3">
          {Residents.map(({ node: post }) => (


            <Link className="text-purple-800 mr-2 whitespace-no-wrap" to={`/residents/${post.slug}`}>{post.companyName}</Link>
          
          ))}
        </div>
      </div>
    </Layout >
  );
};
export default Resident;






export const pageQuery = graphql`
  query($slug: String!) {
    contentfulResident(slug: { eq: $slug }) {
      companyName
      logo {
        fluid(maxWidth: 2000, resizingBehavior: SCALE) {
          ...GatsbyContentfulFluid_tracedSVG
        }
      }
      slug
      pitch {
          pitch
      }
      bio {
        json
      }
      location
      website
      contacts {
        name
        email
        title
      }
    }
    allContentfulResident(sort: {fields: companyName, order: ASC}) {
      edges {
        node {
          id
          slug
          companyName
        }
      }
    }
    file(relativePath: { eq: "banner-residents.jpg" }) {
      childImageSharp {
        fixed(width: 1200) {
          ...GatsbyImageSharpFixed
        }
      }
    }    
  }
`;